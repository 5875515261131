import { yupResolver } from '@hookform/resolvers/yup';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { DataGrid, GridColumns } from '@material-ui/data-grid';
import { ConfirmButton } from '~/components/Buttons';
import DefaultLoader from '~/components/DefaultLoader';
import { Fildset } from '~/components/Fildset';
import FooterDefault from '~/components/FooterDefault';
import { InputSelect, InputText } from '~/components/NovosInputs';
import { LojaContext } from '~/context/loja';
import useAuth from '~/hooks/useAuth';
import api from '~/services/api';
import { nanoid } from 'nanoid';
import React, {
  KeyboardEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { BsPlusLg } from 'react-icons/bs';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { toast } from 'react-toastify';

import { ButtonContainerModal } from './component/ButtonContainerModal';
import { TableRowModal } from './component/TableRowModal';
import { ButtonRow, ButtonRowPlus, Container, Modal } from './styles';
import {
  IGrupos,
  IStorageUser,
  ITipoItem,
  SelectProps,
  ISelect,
} from './types';
import { schema } from './Validations';
import { loadingContext } from '~/context/loading';
import { HotKeys, configure } from 'react-hotkeys';
import { useQueryClient } from 'react-query';

const Departamento: React.FC = () => {
  const { loja } = useContext(LojaContext);
  const {
    register,
    handleSubmit,
    control,
    setValue,
    resetField,
    unregister,
    getValues,
    clearErrors,
    formState: { errors },
    setFocus,
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });

  configure({
    ignoreTags: ['input', 'select', 'textarea'],
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ignoreEventsCondition(keyEvent: any) {
      return false;
    },
  });

  const user = useAuth();
  const queryClient = useQueryClient();
  const { loadingDepartamento, setLoadingDepartamento } =
    useContext(loadingContext);
  const [isUpdate, setIsUpdate] = useState(false);
  const [nivelGrid, setNivelGrid] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [tipoItem, setTipoItem] = useState<any>('');
  const [autRows, setAutRows] = useState<any>([]);
  const [storageUser, setStorageUser] = useState<IStorageUser>();
  const [rowSecao, setRowSecao] = useState<IStorageUser[]>([]);
  const [rowGrupos, setRowGrupos] = useState<IStorageUser[]>([]);
  const [rowSubGrupos, setRowSubGrupos] = useState<IStorageUser[]>([]);
  const [secaoView, setSecaoView] = useState<number | undefined>(undefined);
  const [grupoView, setGrupoView] = useState<number | undefined>(undefined);
  const [base, setBase] = useState<any[]>([]);
  const [tipoItemSelectInit, setTipoItemSelectInit] = useState<boolean>(false);
  const [descInit, setDescInit] = useState<boolean>(false);
  const [descricao, setDescricao] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [idContabeis, setIdContabeis] = useState<ISelect[]>([]);

  const colunasTabSubgrupo: GridColumns = [
    {
      field: 'id',
      headerName: 'Id',
      hide: true,
      width: 200,
      sortable: false,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'voltar',
      headerName: 'voltar',
      width: 80,
      sortable: false,
      headerClassName: 'super-app-theme--header',
      renderHeader: () => (
        <FaAngleLeft
          onClick={() => {
            setBase([]);
            setNivelGrid(nivelGrid - 1);
          }}
          title="Criar Seção "
          size={25}
          color="#fff"
        />
      ),
    },
    {
      field: 'secao',
      headerName: 'SUBGRUPOS',
      flex: 3,
      sortable: false,
      headerClassName: 'super-app-theme--header',
      renderCell: (params: any) => {
        const onEdit = () => {
          const { row } = params;

          const infoSelec = tipoItem.filter(
            (item: any) => item.value === row.cod_tipo_item,
          )[0];

          const cod_id_ctb = idContabeis.filter(
            (item: any) => item.value === row.cod_id_ctb,
          )[0];

          onEditTab(row);
          setValue('inputValue', row.secao);
          setDescricao(row.secao);
          setValue('infoSelec', infoSelec);
          setValue('cod_id_ctb', cod_id_ctb);
          setValue('cod_dep', row.id);
          setIsUpdate(true);
        };

        return (
          <div onClick={onEdit} style={{ width: '100%', cursor: 'pointer' }}>
            <ButtonRowPlus>{params.row.secao}</ButtonRowPlus>
          </div>
        );
      },
    },
    {
      field: '',
      headerName: '',
      flex: 1,
      sortable: false,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'right',
      renderHeader: () => (
        <ButtonRowPlus
          type="button"
          onClick={onVisualiseCriarEditar}
          title="Criar Seção"
          style={{ textAlign: 'right' }}
        >
          <BsPlusLg title="Criar Seção " size={25} color="#fff" />
        </ButtonRowPlus>
      ),
      renderCell: (params: { row: any }) => {
        const onEdit = () => {
          const { row } = params;
          onEditTab(row);
          setValue('inputValue', row.secao);
          setValue('cod_dep', row.id);
          setIsUpdate(true);
        };
        const onDelete = () => {
          const { row } = params;
          handleDelete(row.id, nivelGrid, row.cod_departamento_pai);
        };

        return (
          <ButtonContainerModal actionDelete={onDelete} actionEdit={onEdit} />
        );
      },
    },
  ];

  const colunasTabGrupos: GridColumns = [
    {
      field: 'id',
      headerName: 'Id',
      hide: true,
      width: 200,
      sortable: false,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'voltar',
      headerName: 'voltar',
      width: 80,
      sortable: false,
      headerClassName: 'super-app-theme--header',
      renderHeader: () => (
        <FaAngleLeft
          onClick={() => {
            setValue('grupo', '');
            setNivelGrid(nivelGrid - 1);
          }}
          title="Criar Seção "
          size={25}
          color="#fff"
        />
      ),
    },
    {
      field: 'secao',
      headerName: `GRUPOS`,
      flex: 3,
      sortable: false,
      headerClassName: 'super-app-theme--header',
      renderCell: (params: any) => {
        const onRow = () => {
          onRowClick(params);
        };

        return (
          <div onClick={onRow} style={{ width: '100%', cursor: 'pointer' }}>
            <ButtonRowPlus>{params.row.secao}</ButtonRowPlus>
          </div>
        );
      },
    },
    {
      field: '',
      headerName: '',
      flex: 1,
      sortable: false,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'right',
      renderHeader: () => (
        <ButtonRowPlus
          type="button"
          onClick={onVisualiseCriarEditar}
          title="Criar Seção"
          style={{ textAlign: 'right' }}
        >
          <BsPlusLg title="Criar Seção " size={25} color="#fff" />
        </ButtonRowPlus>
      ),
      renderCell: (params: { row: any }) => {
        const onEdit = () => {
          const { row } = params;
          onEditTab(row);
          setValue('inputValue', row.secao);
          setDescricao(row.secao);
          setValue('cod_dep', row.id);
          setIsUpdate(true);
        };
        const onDelete = () => {
          const { row } = params;
          handleDelete(row.id, nivelGrid, row.cod_departamento_pai);
        };
        const onVisualise = () => {
          const { row } = params;
          setValue('grupo', row.id);
          setValue('nivel', row.num_nivel + 1);
          setGrupoView(row.secao);
          setNivelGrid(nivelGrid + 1);
          getSubgrupos(row.id);
        };

        return (
          <>
            <ButtonContainerModal actionDelete={onDelete} actionEdit={onEdit} />

            <ButtonRow type="button" title="Visualizar" onClick={onVisualise}>
              <FaAngleRight size={30} style={{ color: '#3c7ae6' }} />
            </ButtonRow>
          </>
        );
      },
    },
  ];

  const colunasTabSecoes: GridColumns = [
    {
      field: 'id',
      headerName: 'Id',
      hide: true,
      width: 200,
      sortable: false,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'secao',
      headerName: 'SEÇÃO',
      flex: 3,
      sortable: false,
      headerClassName: 'super-app-theme--header',
      renderCell: (params: any) => {
        const onRow = () => {
          onRowClick(params);
        };

        return (
          <div onClick={onRow} style={{ width: '100%', cursor: 'pointer' }}>
            <ButtonRowPlus>{params.row.secao}</ButtonRowPlus>
          </div>
        );
      },
    },
    {
      field: '',
      headerName: '',
      flex: 1,
      sortable: false,
      headerClassName: 'super-app-theme--header',
      renderHeader: () => {
        return (
          <ButtonRowPlus
            type="button"
            onClick={onVisualiseCriarEditar}
            title="Criar Seção"
            style={{ textAlign: 'right' }}
          >
            <BsPlusLg title="Criar Seção " size={25} color="#fff" />
          </ButtonRowPlus>
        );
      },
      renderCell: (params: { row: any }) => {
        const onEdit = () => {
          const { row } = params;
          onEditTab(row);
          setValue('inputValue', row.secao);
          setDescricao(row.secao);
          setValue('cod_dep', row.id);
          setIsUpdate(true);
        };
        const onDelete = () => {
          const { row } = params;
          handleDelete(row.id, nivelGrid, nivelGrid);
        };
        const onVisualise = () => {
          const { row } = params;
          setValue('secao', row.id);
          setSecaoView(row.secao);
          setValue('nivel', row.num_nivel + 1);

          setNivelGrid(nivelGrid + 1);
          setRowGrupos([]);
          getGrupos(row.id);
        };

        return (
          <>
            <ButtonContainerModal actionDelete={onDelete} actionEdit={onEdit} />

            <ButtonRow type="button" title="Visualizar" onClick={onVisualise}>
              <FaAngleRight size={30} style={{ color: '#3c7ae6' }} />
            </ButtonRow>
          </>
        );
      },
    },
  ];
  const colunasTabEditModal: GridColumns = [
    {
      field: 'id',
      headerName: 'Loja',
      flex: 1,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'super-app-theme--header',
    },
    {
      field: '',
      headerName: 'R$ Base',
      flex: 3,
      headerAlign: 'right',
      sortable: false,
      headerClassName: 'super-app-theme--header',
    },
  ];

  const getRowEditModal = useCallback(
    async (update: boolean, storage: any, row?: any) => {
      const cod_departamento = row ? row.id : getValues('grupo');

      if (cod_departamento !== undefined && getValues('grupo') !== '') {
        const { data } = await api.get(
          `/departamento/${cod_departamento}/margem/${storage.id}`,
        );

        const validPrecoValues = ['PADRÃO', 'PADRAO', null];

        if (!update) {
          const linhas: any[] = [];
          const baseArrayObj: any[] = [];

          data.data.forEach((item: any) => {
            const checker = validPrecoValues.includes(item.des_preco);

            if (checker) {
              const baseArray = { cod_loja: undefined, val_margem: undefined };
              baseArray.cod_loja = item.cod_loja;
              baseArray.val_margem =
                item.val_margem_ref === null ? 0 : item.val_margem_ref;
              baseArrayObj.push(baseArray);
              linhas.push(item);
            }
          });

          setBase(baseArrayObj);
          setAutRows(linhas);
        } else {
          if (tipoItem && row.cod_tipo_item !== null) {
            const option2 = tipoItem.filter(
              (item: any) => item.value === row.cod_tipo_item,
            );
            setValue('infoSelec', option2[0]);
          }
          if (idContabeis && row.cod_id_ctb !== null) {
            const option2 = idContabeis.filter(
              (item: any) => item.value === row.cod_id_ctb,
            );
            setValue('cod_id_ctb', option2[0]);
          }
          const linhas: any[] = [];
          const baseArrayObj: any[] = [];

          data.data.forEach((item: any) => {
            const checker = validPrecoValues.includes(item.des_preco);

            if (checker) {
              const baseArray = { cod_loja: undefined, val_margem: undefined };
              baseArray.cod_loja = item.cod_loja;
              baseArray.val_margem =
                item.val_margem_ref === null ? 0 : item.val_margem_ref;
              baseArrayObj.push(baseArray);

              setValue(
                `porcent${item.cod_loja}`,
                item.val_margem_ref === null
                  ? 0
                  : item.val_margem_ref.toLocaleString('pt-BR', {
                      minimumFractionDigits: 2,
                    }),
              );
              linhas.push(item);
            }
          });

          setBase(baseArrayObj);
          setAutRows(linhas);
        }
      }
    },
    [tipoItem, getValues, setValue, idContabeis],
  );

  const handleShow = useCallback(() => {
    setShowModal(true);
    setTimeout(() => {
      if (getValues('inputValue')) {
        setFocus('inputValue');
      }
    }, 100);
  }, [setFocus, getValues]);

  const onEditTab = useCallback(
    async (row: any) => {
      await getRowEditModal(true, storageUser, row);
      handleShow();
    },
    [storageUser, getRowEditModal, handleShow],
  );

  useEffect(() => {
    (async () => {
      const { data } = await api.get(`/perfil-documento-id-contabil`);
      if (data.success) {
        const newIdContabeis = data.data
          .map((c: any) => ({
            label: `${c.cod_id_ctb} - ${c.des_id_ctb}`,
            value: c.cod_id_ctb,
          }))
          .sort((a: any, b: any) => a.value - b.value);

        setIdContabeis(newIdContabeis);
      }
    })();
  }, []);

  useEffect(() => {
    const nivel = getValues('nivel');
    if (nivelGrid !== nivel) {
      setValue('nivel', nivelGrid);
    }
  }, [nivelGrid, getValues, setValue]);

  const getTipoItem = useCallback(async () => {
    const { data } = await api.get('/tipo_item');
    if (data.success) {
      const options = data.data
        .map((tipo: ITipoItem) => {
          return {
            label: `${tipo.num_tipo_item} - ${tipo.des_tipo_item}`,
            value: tipo.cod_tipo_item,
          };
        })
        .sort(function (x: any, y: any) {
          return x.value - y.value;
        });
      setTipoItem(options);
    }
  }, []);

  const getGrupos = useCallback(
    async (cod_secao: number) => {
      setLoadingDepartamento(true);
      getTipoItem();
      const { data } = await api.get(`/departamento/filhos/${cod_secao}`);
      if (data.count > 0) {
        const options = data.data.map((gru: IGrupos) => ({
          id: gru.cod_departamento,
          secao: gru.des_departamento,
          num_nivel: gru.num_nivel,
          cod_departamento_pai: gru.cod_departamento_pai,
          cod_tipo_item: gru.cod_tipo_item,
          // cod_id_ctb: gru.cod_id_ctb,
        }));
        setRowGrupos(options);
        setLoadingDepartamento(false);
      } else {
        setRowGrupos([]);
      }
      setLoadingDepartamento(false);
    },
    [getTipoItem, setLoadingDepartamento],
  );

  const getSubgrupos = useCallback(
    async (cod_secao: number) => {
      setLoadingDepartamento(true);
      const { data } = await api.get(`/departamento/filhos/${cod_secao}`);

      if (data.count > 0) {
        const options = data.data.map((gru: IGrupos) => ({
          id: gru.cod_departamento,
          secao: gru.des_departamento,
          num_nivel: gru.num_nivel,
          cod_departamento_pai: gru.cod_departamento_pai,
          cod_tipo_item: gru.cod_tipo_item,
          cod_id_ctb: gru.cod_id_ctb,
        }));
        setRowSubGrupos(options);
      } else {
        setRowSubGrupos([]);
      }
      setLoadingDepartamento(false);
    },
    [setLoadingDepartamento],
  );

  const onVisualiseCriarEditar = useCallback(async () => {
    setBase([]);
    await getRowEditModal(false, storageUser);
    setValue('infoSelec', '');
    setTimeout(() => {
      setValue('cod_id_ctb', {
        value: 99999999,
        label: '99999999 - A DEFINIR',
      });
    }, 100);
    setValue('inputValue', '');
    setIsUpdate(false);
    handleShow();
  }, [storageUser, getRowEditModal, setValue, handleShow]);

  useEffect(() => {
    (async () => {
      setLoadingDepartamento(true);
      const { data } = await api.get('departamento/filhos');
      if (data.success) {
        if (data.count > 0) {
          const options = data.data.map((gru: IGrupos) => ({
            id: gru.cod_departamento,
            secao: gru.des_departamento,
            num_nivel: gru.num_nivel,
            cod_departamento_pai: gru.cod_departamento_pai,
            cod_tipo_item: gru.cod_tipo_item,
            cod_id_ctb: gru.cod_id_ctb,
          }));

          setRowSecao(options);
          setLoadingDepartamento(false);
        } else {
          setLoadingDepartamento(false);
        }
      }
      const storage = returnStorage();
      setStorageUser(storage);
    })();
  }, []);

  const rowUpdate = async () => {
    setLoadingDepartamento(true);
    const { data } = await api.get('departamento/filhos');
    if (data.success) {
      if (data.count > 0) {
        const options = data.data.map((gru: IGrupos) => ({
          id: gru.cod_departamento,
          secao: gru.des_departamento,
          cod_tipo_item: gru.cod_tipo_item,
          num_nivel: gru.num_nivel,
          cod_id_ctb: gru.cod_id_ctb,
        }));

        setRowSecao(options);
        setLoadingDepartamento(false);
      }
    }
  };

  const updateTab = useCallback(
    (nivelSecao: any, cod_pai: any) => {
      if (nivelSecao === 1) {
        rowUpdate();
      } else if (nivelSecao === 2) {
        getGrupos(cod_pai);
      } else {
        getSubgrupos(cod_pai);
      }
    },
    [getGrupos, getSubgrupos],
  );

  const handleDelete = useCallback(
    async (param: number, num_nivel: number, secao_id: number) => {
      let textTrue: string;
      let textError = '';

      if (num_nivel === 1) {
        textTrue = 'Seção removida';
        textError =
          'Não foi possível remover a seção, pois existem grupos relacionados a ela';
      } else if (num_nivel === 2) {
        textTrue = 'Grupo removido';
        textError =
          'Não foi possível remover o grupo, pois existem subgrupos relacionados a ela';
      } else {
        textTrue = 'Subgrupo removido';
      }

      try {
        const { data } = await api.delete(`/departamento/${param}`);
        if (data.success) {
          setLoadingDepartamento(true);
          updateTab(num_nivel, secao_id);
          setLoadingDepartamento(false);

          if (num_nivel === 3) {
            const { data: dataChild } = await api.get(
              `/departamento/filhos/${secao_id}`,
            );

            const { data: child } = dataChild;

            const itemRemoved: IGrupos[] = child.filter(
              (item: IGrupos) => item.cod_departamento !== param,
            );

            const items = itemRemoved.map((item) => ({
              id: item.cod_departamento,
              secao: item.des_departamento,
              num_nivel: item.num_nivel,
              cod_departamento_pai: item.cod_departamento_pai,
              cod_tipo_item: item.cod_tipo_item,
              cod_id_ctb: item.cod_id_ctb,
            }));

            setRowSubGrupos(items.length > 0 ? items : []);
          }

          toast.success(`${textTrue} com sucesso`);
        } else {
          if (data.message === 'Existe produto vinculado') {
            return toast.warning(
              `Não foi possível remover esse departamento, pois existem produtos vinculados`,
            );
          }
          toast.warning(`${textError}`);
        }
      } catch (e: any) {
        toast.warning(e.data.message);
      }
    },
    [updateTab, setLoadingDepartamento],
  );

  const onSave = handleSubmit(async (data) => {
    const cod_pai = codPaiSelect(nivelGrid, data.secao, data.grupo);
    let validaRegraEmpresa = false;

    for (let i = 0; i < base.length; i++) {
      if (
        base[i].val_margem > 99.99 &&
        loja.regra_empresa.tipo_margem > 0 &&
        loja.regra_empresa.tipo_margem < 4
      ) {
        toast.warning(
          <>
            <div>Margem de referência informada é maior que 99,99%</div>
            <div style={{ marginTop: '5px' }}>
              Não permitida para o tipo de margem parametrizado na regra de
              empresa.
            </div>
          </>,
        );
        validaRegraEmpresa = true;
        break;
      }
    }

    if (!validaRegraEmpresa) {
      setLoadingDepartamento(true);

      try {
        if (isUpdate) {
          const data2 = await api.put(`/departamento/${data.cod_dep}`, {
            des_departamento: data.inputValue || descricao,
            num_nivel: nivelGrid,
            cod_departamento_pai: cod_pai,
            cod_usuario_cadastro: user.user && user.user.id,
            cod_tipo_item: nivelGrid === 3 ? data.infoSelec.value : null,
            cod_id_ctb: nivelGrid === 3 ? data.cod_id_ctb.value : null,
            margem: base,
          });
          if (data2.data.success) {
            handleClose();
            setIsUpdate(false);
            setLoadingDepartamento(false);
            setShowModal(false);

            updateTab(nivelGrid, cod_pai);
            setLoadingDepartamento(false);
            toast.success(data2.data.message);

            return;
          }
          toast.warning(data2.data.message);
        } else {
          const data2 = await api.post(`/departamento`, {
            des_departamento: data.inputValue || descricao,
            num_nivel: nivelGrid,
            cod_departamento_pai: cod_pai,
            cod_usuario_cadastro: user.user && user.user.id,
            cod_tipo_item: nivelGrid === 3 ? data.infoSelec.value : null,
            cod_id_ctb: nivelGrid === 3 ? data.cod_id_ctb.value : null,
            margem: base,
          });
          if (data2.data.success) {
            setShowModal(false);
            handleClose();
            toast.success(data2.data.message);
            updateTab(nivelGrid, cod_pai);
          } else {
            toast.warning(data2.data.message);
          }
        }
        setLoadingDepartamento(false);
        setDescricao('');
      } catch (error: any) {
        setLoadingDepartamento(false);
        toast.warning(error);
      }
    }
  });

  const codPaiSelect = (nivelSecao: any, secaoId: any, grupoId: any) => {
    if (nivelSecao === 1) return null;

    if (nivelSecao === 2) return secaoId;

    if (nivelSecao === 3) return grupoId;
  };

  const handleClose = () => {
    setDescInit(false);
    resetField('inputValue');
    resetField('infoSelec');
    resetField('cod_id_ctb');
    setValue('inputValue', '');
    setValue('infoSelec', '');
    setValue('cod_id_ctb', '');
    setShowModal(false);
    setBase([]);
    const valuesPorcent = getValues();

    const valuesPorcentKey = Object.keys(valuesPorcent);
    valuesPorcentKey.forEach((element) => {
      if (element.includes('porcent')) {
        unregister(`${element}`);
      }
    });
  };

  const returnStorage = () => {
    return JSON.parse(localStorage.getItem('YOUR_KEY_USER_DATA') as string);
  };

  const onRowClick = async (param: any) => {
    const { row } = param;
    setNivelGrid(nivelGrid + 1);
    if (row.num_nivel === 1) {
      setValue('secao', row.id);
      setValue('nivel', row.num_nivel);
      setSecaoView(row.secao);

      setRowGrupos([]);
      getGrupos(row.id);
    } else {
      setValue('grupo', row.id);
      setValue('nivel', row.num_nivel);
      setGrupoView(row.secao);
      setRowSubGrupos([]);
      getSubgrupos(row.id);
    }
  };

  const handleBaseMargem = (value: any, cod_loja: number) => {
    let ValueConverted;
    if (value.includes(',')) {
      const convertedValue = value.replace(',', '');
      const convertedValueString = convertedValue.slice(-2);
      ValueConverted = `${convertedValue.slice(0, -2)},${convertedValueString}`;
    } else {
      ValueConverted = `00,0${value}`;
    }

    const indexBase = base.findIndex((item) => item.cod_loja === cod_loja);
    const baseArrayObj = base;
    const baseArray = base[indexBase];
    baseArray.val_margem = parseFloat(ValueConverted.replace(',', '.'));

    if (
      baseArray.val_margem > 99.99 &&
      loja.regra_empresa.tipo_margem > 0 &&
      loja.regra_empresa.tipo_margem < 4
    ) {
      return toast.warning(
        <>
          <div>Margem de referência informada é maior que 99,99%</div>
          <div style={{ marginTop: '5px' }}>
            Não permitida para o tipo de margem parametrizado na regra de
            empresa.
          </div>
        </>,
      );
    }
    baseArrayObj[indexBase] = baseArray;
    setBase(baseArrayObj);
  };

  useEffect(() => {
    const handleSaveKeyDown = (ev: KeyboardEvent) => {
      ev.stopPropagation();

      if (
        ev.key === 'Enter' &&
        ev.code === 'Enter' &&
        descricao.length > 0 &&
        !isSaving
      ) {
        setDescInit(true);
        setTipoItemSelectInit(true);

        setIsSaving(true);

        onSave();

        setTimeout(() => {
          setIsSaving(false);
        }, 1000);
      }
    };

    document.addEventListener('keydown', handleSaveKeyDown as any);

    return () =>
      document.removeEventListener('keydown', handleSaveKeyDown as any);
  }, [
    nivelGrid,
    onSave,
    secaoView,
    grupoView,
    rowSecao,
    rowGrupos,
    rowSubGrupos,
    descricao,
    isSaving,
  ]);

  useEffect(() => {
    const inputValue = getValues('inputValue') as string;

    if (descricao?.length > 0 || inputValue?.length > 0) {
      clearErrors('inputValue');

      const value = descricao?.length > 0 ? descricao : inputValue;

      setDescricao(value);
      setValue('inputValue', value);
    } else {
      setDescricao('');
      setValue('inputValue', '');
    }
  }, [clearErrors, descricao, getValues, setValue]);

  if (loadingDepartamento) {
    return (
      <Container>
        <div className="w-100 h-100 d-flex justify-conten-center align-items-center">
          <DefaultLoader />
        </div>
      </Container>
    );
  }

  const keyMap = {
    hkSalvar: 'ctrl+alt+s',
    hkCancelar: 'ctrl+alt+d',
  };

  const validaTelaAtalho = () => {
    const cod_tela_active: any = queryClient.getQueryData(`cod_tela_active`);
    if (cod_tela_active) {
      if (cod_tela_active.cod_tela === 20) {
        return true;
      }
    }

    return false;
  };

  const keyHandlers = {
    hkSalvar: () => {
      const validaAtalho = validaTelaAtalho();
      if (validaAtalho) {
        setDescInit(true);
        setTipoItemSelectInit(true);
        onSave();
      }
    },
    hkCancelar: () => {
      const validaAtalho = validaTelaAtalho();
      if (validaAtalho) {
        handleClose();
      }
    },
  };

  return (
    <HotKeys id="hotkeys" keyMap={keyMap} handlers={keyHandlers} tabIndex={-1}>
      <Container>
        <h3>Departamento Mercadológico</h3>
        <hr />
        <Row style={{ minHeight: '500px' }}>
          <Row style={{ marginLeft: '-8px', paddingRight: '14px' }}>
            <Col sm={12} className="ContainerModal">
              <Col
                sm={12}
                md={12}
                style={{ marginTop: '0.25rem' }}
                className="dataTable"
              >
                <Modal size="lg" show={showModal} onHide={handleClose} centered>
                  <Modal.Header>
                    <Modal.Title>
                      <Row>
                        <p>
                          {isUpdate
                            ? `Editar ${
                                nivelGrid === 1 ? 'Seção' : 'Departamento'
                              }`
                            : 'Cadastrar Departamento'}
                        </p>
                      </Row>
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Row>
                      <Col sm={12}>
                        <InputText
                          label="Descrição"
                          maxLength={100}
                          placeholder=""
                          name="inputValue"
                          setFocus={setFocus}
                          toLowerCase={false}
                          register={register}
                          disabled={false}
                          isError={descInit && !!errors.inputValue}
                          onInput={(ev: any) => {
                            const value =
                              ev.target.value.toUpperCase() as string;
                            setValue('inputValue', value);
                          }}
                        />
                      </Col>
                    </Row>

                    {nivelGrid === 3 && (
                      <>
                        <Row>
                          <Col sm={12}>
                            <InputSelect
                              options={tipoItem}
                              label="Tipo do Item:"
                              placeholder={
                                getValues('infoSelec') !== undefined && isUpdate
                                  ? getValues('infoSelec').label
                                  : '- Selecione a Categoria -'
                              }
                              name="infoSelec"
                              register={register}
                              isError={tipoItemSelectInit && !!errors.infoSelec}
                              control={control}
                              changeSelected={(selected: SelectProps) => {
                                setValue('infoSelec', selected);

                                setTipoItemSelectInit(false);
                              }}
                            />
                          </Col>
                          <Col sm={12}>
                            <InputSelect
                              label="ID Contábil"
                              placeholder={
                                getValues('cod_id_ctb') !== undefined &&
                                isUpdate
                                  ? getValues('cod_id_ctb').label
                                  : '- Selecione um ID Contábil -'
                              }
                              name="cod_id_ctb"
                              register={register}
                              isError={!!errors.cod_id_ctb}
                              control={control}
                              changeSelected={(selected: SelectProps) => {
                                clearErrors('cod_id_ctb');
                                setValue('cod_id_ctb', selected);
                              }}
                              options={idContabeis}
                            />
                          </Col>
                        </Row>
                        <Col sm={12} md={12} className="dataTable">
                          <Fildset label="%Margem por Loja">
                            <TableContainer>
                              <div className="mt-2">
                                <Table>
                                  <TableHead>
                                    <TableRow>
                                      {colunasTabEditModal.map((item) => {
                                        if (item.headerName !== null) {
                                          return (
                                            <TableCell key={nanoid()}>
                                              {item.headerName}
                                            </TableCell>
                                          );
                                        }
                                        return <></>;
                                      })}
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    <TableRow key={nanoid()}>
                                      <TableCell style={{ display: 'none' }}>
                                        <p>/</p>
                                      </TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                                {base.length > 0 &&
                                  autRows.map((linha: any) => {
                                    return (
                                      <TableRowModal
                                        key={nanoid()}
                                        register={register}
                                        control={control}
                                        errors={errors}
                                        cod_loja={linha.cod_loja}
                                        baseMargem={(value: any) => {
                                          handleBaseMargem(
                                            value,
                                            linha.cod_loja,
                                          );
                                        }}
                                        setValue={setValue}
                                      />
                                    );
                                  })}
                              </div>
                            </TableContainer>
                          </Fildset>
                        </Col>
                      </>
                    )}
                  </Modal.Body>
                  <Modal.Footer>
                    <ConfirmButton
                      onClick={handleClose}
                      style={{
                        color: '#ffffff',
                        background: '#dc3545',
                        height: '100%',
                        maxHeight: '42px',
                      }}
                      title="CTRL + ALT + D"
                    >
                      CANCELAR
                    </ConfirmButton>
                    <ConfirmButton
                      onClick={() => {
                        setDescInit(true);
                        setTipoItemSelectInit(true);
                        onSave();
                      }}
                      style={{
                        color: '#ffffff',
                        background: '#28a745',
                        height: '100%',
                        maxHeight: '42px',
                      }}
                      title="CTRL + ALT + S"
                    >
                      SALVAR
                    </ConfirmButton>
                  </Modal.Footer>
                </Modal>
                <div style={{ marginTop: '20px;' }}>
                  {nivelGrid === 3 && (
                    <h5>
                      Você está em: {secaoView}
                      {' / '}
                      {grupoView}
                    </h5>
                  )}
                  {nivelGrid === 2 && <h5>Você está em: {secaoView}</h5>}
                </div>

                {nivelGrid === 1 && (
                  <DataGrid
                    autoHeight
                    rows={rowSecao}
                    columns={colunasTabSecoes}
                    disableColumnMenu
                    disableColumnFilter
                    disableColumnSelector
                    hideFooterSelectedRowCount
                    localeText={{
                      noRowsLabel: 'Nenhum registro encontrado',
                      columnMenuLabel: 'Menu',
                      columnMenuFilter: 'Filtrar',
                      columnMenuHideColumn: 'Esconder',
                      columnMenuUnsort: 'Não ordenar',
                      columnMenuSortAsc: 'Ordernar ASC',
                      columnMenuSortDesc: 'Ordernar DESC',
                      columnMenuShowColumns: 'Mostrar colunas',
                    }}
                  />
                )}

                {nivelGrid === 2 && (
                  <DataGrid
                    autoHeight
                    rows={rowGrupos}
                    columns={colunasTabGrupos}
                    disableColumnFilter
                    disableColumnSelector
                    hideFooterSelectedRowCount
                    localeText={{
                      noRowsLabel: 'Nenhum registro encontrado',
                      columnMenuLabel: 'Menu',
                      columnMenuFilter: 'Filtrar',
                      columnMenuHideColumn: 'Esconder',
                      columnMenuUnsort: 'Não ordenar',
                      columnMenuSortAsc: 'Ordernar ASC',
                      columnMenuSortDesc: 'Ordernar DESC',
                      columnMenuShowColumns: 'Mostrar colunas',
                    }}
                  />
                )}

                {nivelGrid === 3 && (
                  <DataGrid
                    autoHeight
                    rows={rowSubGrupos}
                    columns={colunasTabSubgrupo}
                    disableColumnFilter
                    disableColumnSelector
                    hideFooterSelectedRowCount
                    localeText={{
                      noRowsLabel: 'Nenhum registro encontrado',
                      columnMenuLabel: 'Menu',
                      columnMenuFilter: 'Filtrar',
                      columnMenuHideColumn: 'Esconder',
                      columnMenuUnsort: 'Não ordenar',
                      columnMenuSortAsc: 'Ordernar ASC',
                      columnMenuSortDesc: 'Ordernar DESC',
                      columnMenuShowColumns: 'Mostrar colunas',
                    }}
                  />
                )}
              </Col>
            </Col>
          </Row>
        </Row>
        <hr />
        <FooterDefault codTela={20} />
      </Container>
    </HotKeys>
  );
};

export default Departamento;
